import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import LoadingSpinner from '../LoadingSpinner';

export default function LoginForm({ onSubmit, error, enterToken, submitting, frontendVersion, backendVersion, country }) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [token, setToken] = useState('');
	return <Container className="d-flex flex-column">
		<div className="d-flex flex-column align-items-center justify-content-center mt-5">
			<div className="col-md-6 bg-white rounded border p-5">
				<h1 className="text-center pb-4"><img alt="" src="/plutus.png" height="50" /> Plutus for Brainpoint Pay</h1>
				<Form onSubmit={e => {
					e.preventDefault();
					onSubmit(email, password, token);
				}}>
					{error && <Alert variant="danger">
						<h4>Error</h4>
						{error.message}
					</Alert>}
					{!enterToken && <>
						<Form.Group className="mb-2">
							<Form.Label>Email:</Form.Label>
							<Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} autoFocus />
						</Form.Group>
						<Form.Group className="mb-4 pb-2">
							<Form.Label>Password:</Form.Label>
							<Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} />
						</Form.Group>
					</>}
					{enterToken && <Form.Group className="mb-4">
						<Form.Label>2FA token:</Form.Label>
						<Form.Control type="text" value={token} onChange={e => setToken(e.target.value)} autoFocus />
					</Form.Group>}
					<div className="d-flex flex-column align-items-center justify-content-center">
						<div className="w-50">
							{submitting && <LoadingSpinner />}
							{!submitting && <Form.Group>
								<Form.Control className="btn btn-primary" type="submit" value={enterToken? 'Submit' : 'Login'} disabled={!email || !password || enterToken && !token} />
							</Form.Group>}
						</div>
					</div>
				</Form>
			</div>
		</div>
		<div className="position-absolute bottom-0 start-0 text-secondary p-2">
			<Row>
				{country && <Col><img alt={country} src={`/flag_${country.toLowerCase()}.svg`} className="flag-login" /></Col>}
				<Col className="text-nowrap"><small>{backendVersion && <> Backend: {backendVersion} <br/> Frontend: {frontendVersion} </>}</small></Col>
			</Row>
		</div>
	</Container>;
}

import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

function LoadingSpinner() {
	return <Container className="d-flex justify-content-center align-items-center">
		<Spinner className='m-4' animation="border" variant="dark" />
	</Container>;
}

export function ErrorOrLoadingSpinner({ error }) {
	if(error) {
		return <Container>
			<Alert variant="danger">{error}</Alert>
		</Container>;
	}
	return <LoadingSpinner />;
}

export default LoadingSpinner;
